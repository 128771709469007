import { render, staticRenderFns } from "./AddAppDrawer.vue?vue&type=template&id=bd51bfd4&scoped=true&"
import script from "./AddAppDrawer.vue?vue&type=script&lang=js&"
export * from "./AddAppDrawer.vue?vue&type=script&lang=js&"
import style0 from "./AddAppDrawer.vue?vue&type=style&index=0&id=bd51bfd4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd51bfd4",
  null
  
)

export default component.exports