<template>
  <div class="add-app-drawer">
    <a-drawer
      title="新增应用"
      width="850"
      :closable="true"
      :visible="visible"
      :getContainer="getContainer"
      @close="onClose"
    >
      <a-form-model ref="form" :model="form" :rules="rules">
        <!-- 上传应用图标 -->
        <a-form-model-item
          label="应用图标"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
          prop="icon"
        >
          <div v-if="tempIconUrl" class="icon-preview">
            <img class="icon-img" :src="tempIconUrl" alt="avatar" />
            <a-icon
              class="close-btn"
              type="close-circle"
              theme="filled"
              @click="onRemoveClick"
            />
          </div>
          <div v-else class="cover-upload">
            <a-upload
              name="icon"
              list-type="picture-card"
              class="icon-uploader"
              accept=".jpg,.jpeg,.png"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              @change="handleChange"
            >
              <a-icon
                :type="'plus'"
                style="font-size: 20px"
              />
            </a-upload>
          </div>
        </a-form-model-item>
        <a-form-model-item
          label="应用名称"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
          prop="app_name"
        >
          <a-input v-model="form.app_name" placeholder="请输入应用名称" />
        </a-form-model-item>
        <a-form-model-item
          label="应用链接"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
          prop="url"
        >
          <a-input v-model="form.url" placeholder="请输入应用链接" />
        </a-form-model-item>
        <a-form-model-item
          label="应用简介"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
          prop="summary"
        >
          <a-input
            v-model="form.summary"
            type="textarea"
            :rows="4"
            placeholder="请输入应用简介"
          />
        </a-form-model-item>
        <a-form-model-item
          label="应用分类"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
          prop="category"
        >
          <a-select v-model="form.category" placeholder="请选择应用分类">
            <a-select-option
              v-for="category in categoryOptions"
              :key="category._id"
              :value="category._id"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 输入应用标签 -->
        <a-form-model-item
          label="应用标签"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
          prop="tags"
        >
          <a-select
            v-model="form.tags"
            mode="tags"
            placeholder="请输入应用标签，按回车添加"
            @change="onTagsChange"
          >
            <a-select-option v-for="tag in tags" :key="tag.id" :value="tag.id">
              {{ tag.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="测试网应用"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
          prop="is_testnet_app"
        >
          <a-radio-group v-model="form.is_testnet_app">
            <a-radio :value="true">是</a-radio>
            <a-radio :value="false">否</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          label="预览图"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
          prop="previews"
        >
          <div
            class="preview-item"
            v-for="(preview, index) in tempPreviewUrls"
            :key="index"
          >
            <img class="preview-img" :src="preview" alt="preview" />
            <a-icon
              class="close-btn"
              type="close-circle"
              theme="filled"
              @click="onPreviewRemoveClick(index)"
            />
          </div>
          <div v-if="tempPreviewUrls.length < 5" class="preview-item">
            <a-upload
              name="previews"
              list-type="picture-card"
              class="preview-uploader"
              accept=".jpg,.jpeg,.png"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              @change="handlePreviewChange"
            >
              <a-icon
                :type="'plus'"
                style="font-size: 20px"
              />
            </a-upload>
          </div>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 20, offset: 4 }">
          <a-button type="primary" @click="onSubmitClick" :loading="loading">
            提交
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
  </div>
</template>

<script>
export default {
  name: 'AddAppDrawer',
  data() {
    return {
      visible: false,
      form: {
        is_add_directly: true,
        app_name: '',
        url: '',
        summary: '',
        category: undefined,
        tags: [],
        is_testnet_app: undefined,
        icon: undefined,
        previews: [],
      },
      rules: {
        icon: [
          {
            required: true,
            message: '请上传应用图标',
          },
        ],
        app_name: [
          {
            required: true,
            message: '请输入应用名称',
          },
        ],
        url: [
          {
            required: true,
            message: '请输入应用链接',
          },
          {
            type: 'url',
            message: '请输入正确的应用链接',
          },
        ],
        summary: [
          {
            required: true,
            message: '请输入应用简介',
          },
        ],
        category: [
          {
            required: true,
            message: '请选择应用分类',
          },
        ],
        tags: [
          {
            required: true,
            message: '请输入应用标签',
          },
        ],
        is_testnet_app: [
          {
            required: true,
            message: '请选择是否为测试网应用',
          },
        ],
        previews: [
          {
            required: true,
            message: '请上传应用预览图',
          },
        ],
      },
      categoryOptions: [],
      tags: [],
      // iconFile: null,
      tempIconUrl: '',
      tempPreviewUrls: [],
      loading: false,
    };
  },
  mounted() {
    this.getCategoryList();
  },
  methods: {
    getContainer() {
      return document.querySelector('.add-app-drawer');
    },
    open() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    // 输入应用标签，按回车添加
    onTagsChange(value) {
      this.form.tags = value;
    },
    // 获取应用分类列表
    async getCategoryList() {
      const { err, res } = await this.$api.app.categoryList();
      if (!err) {
        this.categoryOptions = res.result.list;
      }
    },
    // 提交
    onSubmitClick() {
      console.log(this.form);
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const formData = new FormData();
          for (let key in this.form) {
            if (key === 'previews') {
              this.form[key].forEach((preview, index) => {
                formData.append(`${key}${index}`, preview);
              });
            } else if (key === 'tags') {
              formData.append(key, JSON.stringify(this.form[key]));
            } else {
              formData.append(key, this.form[key]);
            }
          }
          const { err, res } = await this.$api.app.add(formData);
          if (!err) {
            this.$message.success(res.msg);
            this.$refs.form.resetFields();
            this.$emit('done');
            this.onClose();
          }
          this.loading = false;
        }
      });
    },
    beforeUpload(file) {
      // 限制图片大小在500kb以内
      const isLt500kb = file.size / 1024 / 1024 < 0.5;
      if (!isLt500kb) {
        this.$message.error('Image must smaller than 500kb!');
        return false;
      }
      return false;
    },
    handleChange(info) {
      // debugger
      // if (info.file.status === 'uploading') {
      //   this.loading = true;
      //   return;
      // }
      // if (info.file.status === 'done') {
      // 用于上传的文件变量
      // this.iconFile = info.file;
      // 用于表单校验
      this.form.icon = info.file;
      // Get this url from response in real world.
      this.getBase64(info.file, (imageUrl) => {
        // 临时展示
        this.tempIconUrl = imageUrl;
        // 单独校验封面
        this.$refs.form.validateField('icon');
      });
      // }
    },
    handlePreviewChange(info) {
      // 限制图片大小在500kb以内
      const isLt500kb = info.file.size / 1024 / 1024 < 0.5;
      if (!isLt500kb) {
        // this.$message.error('Image must smaller than 500kb!');
        return false;
      }
      this.form.previews.push(info.file);
      this.getBase64(info.file, (imageUrl) => {
        this.tempPreviewUrls.push(imageUrl);
        this.$refs.form.validateField('previews');
      });
    },
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    },
    onRemoveClick() {
      this.tempIconUrl = '';
      this.form.icon = '';
    },
    onPreviewRemoveClick(index) {
      this.tempPreviewUrls.splice(index, 1);
      this.form.previews.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.add-app-drawer {
  ::v-deep .icon-preview {
    display: inline-block;
    position: relative;
    img {
      border: 1px solid #ebedf0;
      border-radius: 4px;
      height: 102px;
    }
  }
  .close-btn {
    display: none;
    position: absolute;
    font-size: 20px;
    color: rgb(255, 120, 86);
    right: -5px;
    top: -5px;
    cursor: pointer;
  }
  &:hover {
    .close-btn {
      display: block;
    }
  }
  .preview-item {
    display: inline-block;
    vertical-align: top;
    margin-right: 16px;
    position: relative;
    .preview-img {
      width: 115.2px;
      height: 204.8px;
      border: 1px solid #ebedf0;
    }
  }
}
</style>
